import { Directive, Output, Input, EventEmitter, HostListener } from '@angular/core';

@Directive({
    selector: '[appDragDrop]'
})
export class DragDropDirective {
    @Output() onFileDropped = new EventEmitter<FileList>();
    @Input() disabled : boolean = false;

    //Dragover listener
    @HostListener('dragover', ['$event']) onDragOver(evt) {      
    
        evt.preventDefault();
        evt.stopPropagation();          
    }

    //Dragleave listener
    @HostListener('dragleave', ['$event']) public onDragLeave(evt) {      
        
        evt.preventDefault();
        evt.stopPropagation();       
    }

    //Drop listener
    @HostListener('drop', ['$event']) public ondrop(evt) {
      
        if (this.disabled) return false;
        
        evt.preventDefault();
        evt.stopPropagation();
        let files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.onFileDropped.emit(files)
        }
    }
}
