import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'tdc-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss']
})
export class InfoBoxComponent implements OnInit {

  @Input()
  titleText: string;

  @Input()
  linkText: string;

  @Output()
  click: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

}
