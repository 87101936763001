<div class="container-title">
    <div class="mat-title title">{{(Consultation$ | async)?.Name}}</div>
</div>
<div class="navigation mat-subheading-1"><a [routerLink]="['/my-council/public-consultation/search/1']">Closed
    consultations</a>
</div>

<form class="table-container">
    <p class="description mat-body-2">Select a submission from the list below to view more details.</p>
    <div fxLayout="row" fxLayoutAlign="center baseline">
        <mat-form-field appearance="outline" class="search-field">
            <input #search matInput [formControl]="searchControl" placeholder="Search">
        </mat-form-field>
    </div>
    <mat-hint *ngIf="!searchControl.value" class="search-hint">
        Type the keywords you want to find within these submissions including names, questions and answers
    </mat-hint>
    <mat-hint *ngIf="searchControl.value && searchControl.value.length < 3" class="search-hint">
        Minimum of 3 characters required
    </mat-hint>

    <table mat-table matSort [dataSource]="closedConsultationSubmissionDataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="FullName">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Submitted by</th>
            <td mat-cell *matCellDef="let element">{{element.FullName}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            [routerLink]="['/my-council/public-consultation/search/submission', (Consultation$ | async)?.RoundID, row.SubmissionsId]">
        </tr>
    </table>
    <mat-hint *ngIf="HasNoSearchResults" class="no-results">
        Your search - <b>{{searchControl.value}}</b> - did not match any submissions.
    </mat-hint>
    <mat-paginator #paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</form>
