import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SubmissionSummary} from '../../../models/submissionSummary';
import {Observable} from 'rxjs';
import {Consultation} from '../../../models/consultation';
import {File} from '../../../models/file';
import {filter, map} from "rxjs/operators";
import {saveAs} from 'file-saver/dist/FileSaver';
import {PastSubmissionsService} from "../../services/past-submissions.service";
import {formatForUri} from "../../../shared/helpers/router";
import {b64toBlob} from "../../../shared/helpers/file";

@Component({
    selector: 'tdc-submission',
    templateUrl: './submission.component.html',
    styleUrls: ['./submission.component.scss']
})
export class SubmissionComponent implements OnInit {
    Consultation$: Observable<Consultation>;
    Summaries$: Observable<SubmissionSummary[]>;
    FullName$: Observable<string>;

    formatForUri = formatForUri;

    isDownloadComplete: boolean = false;
    files: File[] = [];

    constructor(private pastSubmissionsService: PastSubmissionsService, private route: ActivatedRoute) {
    }

    public ngOnInit(): void {
        this.Consultation$ = this.pastSubmissionsService.Consultation$;
        this.Summaries$ = this.pastSubmissionsService.SelectedSummaries$;

        this.FullName$ = this.Summaries$.pipe(
            filter(summaries => summaries && summaries.length > 0),
            map(summaries => summaries[0].FullName)
        );

        this.route.params.subscribe(params => {

            let submissionsId = parseInt(params.submissionsId);
            const roundId = parseInt(params.roundId);

            this.pastSubmissionsService.selectRound(roundId);
            this.pastSubmissionsService.selectSubmission(submissionsId);

            this.pastSubmissionsService.downloadCurrentSubmissionFiles()
                .subscribe(
                    (file) => this.files.push(file),
                    () => {},
                    () => this.isDownloadComplete = true);
        });
    }

    public saveFile(file: File): void {
        const blob = b64toBlob(file.Bytes, 'application/octet-stream');
        saveAs(blob, file.Name);
    }
}
