import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatSort} from '@angular/material/sort';
import {ActivatedRoute} from '@angular/router';
import {SubmissionSummary} from '../../../models/submissionSummary';
import {Consultation} from '../../../models/consultation';
import {Observable, Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {PastSubmissionsService} from '../../services/past-submissions.service';
import {UntypedFormControl} from '@angular/forms';

@Component({
    selector: 'app-consultation',
    templateUrl: './consultation.component.html',
    styleUrls: ['./consultation.component.scss']
})
export class ConsultationComponent implements OnInit, OnDestroy {
    closedConsultationSubmissionDataSource: MatTableDataSource<SubmissionSummary>;
    displayedColumns: string[] = ['FullName'];

    @ViewChild('paginator', {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true}) sort: MatSort;

    public searchControl: UntypedFormControl = new UntypedFormControl('');

    private subscription: Subscription;

    constructor(private pastSubmissionsService: PastSubmissionsService, private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.closedConsultationSubmissionDataSource = new MatTableDataSource();
        this.closedConsultationSubmissionDataSource.paginator = this.paginator;
        this.closedConsultationSubmissionDataSource.sort = this.sort;
//        this.closedConsultationSubmissionDataSource.sort.direction = 'desc';


        this.pastSubmissionsService.selectRound(null);

        this.subscription = this.searchControl.valueChanges.pipe(
          switchMap((searchText) =>
            this.pastSubmissionsService.search(searchText.length >= 3 ? searchText : ''))
        )
        .subscribe(submissions => this.refreshSubmissions(submissions));

      this.route.params.subscribe(params => {
        this.closedConsultationSubmissionDataSource.data = [];

        const roundId = parseInt(params.roundId);
        this.pastSubmissionsService.selectRound(roundId);
        this.searchControl.patchValue('');
      });
    }

    ngOnDestroy() {
      this.subscription.unsubscribe();
    }

    private refreshSubmissions(submissions: SubmissionSummary[]) {
    // TODO: Group these in the past submission service
    const uniqueSubmissionsIds: number[] = [];
    const uniqueSubmissions: SubmissionSummary[] = [];

      submissions.forEach(submission => {
      if (!uniqueSubmissionsIds.includes(submission.SubmissionsId)) {
        uniqueSubmissions.push(submission);
        uniqueSubmissionsIds.push(submission.SubmissionsId);
      }
    });

    this.closedConsultationSubmissionDataSource.data = uniqueSubmissions;

  }

    public get Consultation$(): Observable<Consultation> {
        return this.pastSubmissionsService.Consultation$;
    }

    public get HasNoSearchResults(): boolean {
        return this.searchControl.value.length > 0 && this.closedConsultationSubmissionDataSource.filteredData.length === 0;
    }
}
