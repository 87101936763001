import {Component, OnInit, ViewChild} from '@angular/core';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatSort} from '@angular/material/sort';
import {SubmissionService} from '../../services/submission.service';
import {UntypedFormControl} from "@angular/forms";
import {Consultation} from "../../../models/consultation";
import {PastSubmissionsService} from "../../services/past-submissions.service";
import {formatForUri} from "../../../shared/helpers/router";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'tdc-consultation-search',
    templateUrl: './consultation-search.component.html',
    styleUrls: ['./consultation-search.component.scss']
})
export class ConsultationSearchComponent implements OnInit {
    openConsultationDataSource: MatTableDataSource<Consultation>;
    closedConsultationDataSource: MatTableDataSource<Consultation>;

    displayedColumns: string[] = ['Name', 'EndDate'];

    @ViewChild("openConsultationPaginator", {static: true}) openConsultationPaginator: MatPaginator;
    @ViewChild("closedConsultationPaginator", {static: true}) closedConsultationPaginator: MatPaginator;

    @ViewChild("openSort", {static: true}) openSort: MatSort;
    @ViewChild("closedSort", {static: true}) closedSort: MatSort;

    public openSearchControl: UntypedFormControl = new UntypedFormControl('');
    public closedSearchControl: UntypedFormControl = new UntypedFormControl('');

    public default: number = 0;
    formatForUri = formatForUri;

    constructor(private route: ActivatedRoute,
                private submissionService: SubmissionService,
                private pastSubmissionsService: PastSubmissionsService) {
    }

    public ngOnInit(): void {
        this.openConsultationDataSource = new MatTableDataSource();
        this.closedConsultationDataSource = new MatTableDataSource();

        this.openConsultationDataSource.paginator = this.openConsultationPaginator;
        this.closedConsultationDataSource.paginator = this.closedConsultationPaginator;

        this.openConsultationDataSource.sort = this.openSort;
        this.closedConsultationDataSource.sort = this.closedSort;

        this.submissionService.OpenConsultations$.subscribe(consultations => this.openConsultationDataSource.data = consultations);
        this.pastSubmissionsService.ClosedConsultations$.subscribe(consultations => this.closedConsultationDataSource.data = consultations);

        this.openSearchControl.valueChanges.subscribe(value =>
            this.openConsultationDataSource.filter = !!value && value.length >= 3 ? value.trim() : null);

        this.closedSearchControl.valueChanges.subscribe(value =>
            this.closedConsultationDataSource.filter = !!value && value.length >= 3 ? value.trim() : null);

        this.route.params.subscribe(params => {
            this.default = parseInt(params.activeTab);
        });
    }

    public get HasNoOpenSearchResults(): boolean {
        return this.openSearchControl.value.length > 0 && this.openConsultationDataSource.filteredData.length === 0;
    }

    public get HasNoClosedSearchResults(): boolean {
        return this.closedSearchControl.value.length > 0 && this.closedConsultationDataSource.filteredData.length === 0;
    }
}
