<div>
    <p class="title-blue mat-h1">Review Submission</p>

    <p class="title-blue mat-body-2"> About you</p>

    <div *ngIf="userDetails$ | async as userDetails">

        <div class="review">
            <p class="mat-subheading-1">Name</p>
            <p class="mat-body-1">{{userDetails.Title}} {{userDetails.FirstName}} {{userDetails.LastName}}</p>
        </div>

        <div class="review">
            <p class="mat-subheading-1">Organisation you are submitting on behalf of</p>
            <p class="mat-body-1">{{userDetails.Organisation}}</p>
        </div>

        <div class="review">
            <p class="mat-subheading-1">Position</p>
            <p class="mat-body-1">{{userDetails.Designation}}</p>
        </div>

        <div class="review">
            <p class="mat-subheading-1">Address</p>
            <p class="mat-body-1">{{userDetails.Address1}} {{userDetails.Address2}} {{userDetails.City}} {{userDetails.Postcode}}</p>
        </div>

        <div class="review">
            <p class="mat-subheading-1">Phone</p>
            <p class="mat-body-1">{{userDetails.Phone}}</p>
        </div>

        <div *ngIf="showPresentOption" class="review">
            <p class="mat-subheading-1">Do you wish to verbally present in support of your feedback?</p>
            <p *ngIf="userDetails.Speak" class="mat-body-1">Yes</p>
            <p *ngIf="!userDetails.Speak" class="mat-body-1">No</p>
        </div>

        <div *ngIf="userDetails.Speak" class="review">
            <p class="mat-subheading-1">Presentation location</p>
            <p class="mat-body-1">{{userDetails.Location?.LocationName}}</p>
        </div>

    </div>

    <tdc-info-box (click)="goToAboutYou()" titleText="Changed your mind?" linkText="Edit your details"></tdc-info-box>

    <mat-divider></mat-divider>

    <p class="title-blue mat-body-2">Feedback</p>
    <div>
        <div *ngFor="let answer of answers$ | async; let answerIndex = index" [attr.data-index]="answerIndex">
            <div>
                <span class="mat-subheading-1">{{answer.RoundSubject.Subject}}</span>
            </div>

            <div class="review">

                <div *ngIf="answer.MultiChoiceAnswer">
                    <p class="mat-body-1">{{answer.MultiChoiceAnswer.Answer}} </p>
                </div>

                <div *ngIf="answer.AnswerText?.length > 0">
                    <div fxLayout="row"><p class="mat-body-1">{{answer.AnswerText}}</p></div>
                </div>
            </div>
        </div>
    </div>

    <tdc-info-box (click)="goToFeedback()"
                  titleText="You have provided feedback on {{AnsweredCount$ | async}} out of {{TotalAnswerCount$ | async}} questions"
                  linkText="Edit Your Feedback"></tdc-info-box>

    <mat-divider></mat-divider>

    <p class="title-blue mat-body-2">Uploaded files</p>

    <div *ngFor="let file of files$ | async">

        <div fxLayout="row" fxLayoutAlign="left" class="review">
            <span class="mat-body-1">{{file.Name}} ({{file.Size | fileSize}})</span>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="left">
        <P class="mat-subheading-1 padding-top-small">Total File size {{(TotalFileSize$ | async) | fileSize}}</P>
    </div>

    <tdc-info-box (click)="goToFileUpload()"
                  titleText="You have uploaded {{TotalFileCount$ | async}} files"
                  linkText="Upload more files"></tdc-info-box>
</div>
