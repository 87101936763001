import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {File} from 'src/app/models/file';
import {fromEvent, Observable} from "rxjs";
import {filter, map, switchMap, take} from "rxjs/operators";

@Component({
    selector: 'tdc-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

    @Input() files$: Observable<File[]>;

    @Output() fileAdded: EventEmitter<File> = new EventEmitter();
    @Output() fileRemoved: EventEmitter<number> = new EventEmitter();

    TotalFileSize$: Observable<number>;
    UploadDisabled$: Observable<boolean>;

    isInvalidFileType: boolean = false;
    isMaxFileSize: boolean = false;

    static SUPPORTED_FILE_TYPES: string[] = [".pdf", ".doc", ".docx", ".jpeg", ".jpg"];

    static FILE_SIZE_LIMIT: number = 10 * 1024 * 1024;

    constructor() {
    }

    public ngOnInit(): void {
        this.TotalFileSize$ = this.files$.pipe(
            map(files => files.reduce((sum, current) => sum + current.Size, 0))
        );

        // Upload disabled if more than 10MB have been uploaded
        this.UploadDisabled$ = this.TotalFileSize$.pipe(
            map(size => size > FileUploadComponent.FILE_SIZE_LIMIT)
        );
    }

    public uploadFile(submittedFiles: FileList): void {

        let fileArray = Array.from(submittedFiles);

        fileArray.forEach((file, index) => {

            let fileExt = file.name.substring(file.name.lastIndexOf('.'), file.name.length);

            if (FileUploadComponent.SUPPORTED_FILE_TYPES.includes(fileExt.toLowerCase())) {

                let reader = new FileReader();

                fromEvent(reader, 'load').pipe(
                    switchMap(() => this.TotalFileSize$),
                    take(1)
                )
                    .subscribe((totalSize) => {
                        if (totalSize + file.size > FileUploadComponent.FILE_SIZE_LIMIT) {
                            this.isMaxFileSize = true;
                            setTimeout(() => this.isMaxFileSize = false, 3000);
                            return;
                        }

                        let fileData = new File();

                        fileData.Name = file.name;
                        fileData.Bytes = reader.result as string;
                        fileData.Size = file.size;
                        this.fileAdded.emit(fileData);
                    });

                reader.readAsArrayBuffer(file);
            } else {
                this.isInvalidFileType = true;
                setTimeout(() => this.isInvalidFileType = false, 3000);
            }
        });
    }

    public removeFile(index: number): void {
        this.fileRemoved.emit(index);
    }

    public get SupportedFiles(): string {
        return FileUploadComponent.SUPPORTED_FILE_TYPES.toString();
    }
}
