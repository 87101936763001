<div class="navigation mat-subheading-1"><a [routerLink]="['/my-council/public-consultation/search/0']">Open consultations</a></div>

<div class="content-block" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center" fxLayoutAlign.lt-md="center center">

    <mat-card class="submission-cards submission-card-period" fxLayoutAlign="center center">
        
        <div fxLayout="row" fxLayoutAlign="center center" class="row">
            <div fxFlex="20%"><i class="fa fa-calendar fa-3x calendar-after"
                                 aria-hidden="true"></i></div>
            <div fxFlex="80%">
                <div fxLayout="column" fxLayoutAlign="center right" class="right-align">
                    <div>
                        <span class="title-gray mat-h1">Submissions close</span>
                    </div>
                    <div>
                        <span class="title-blue mat-h3">
                            {{(consultation$ | async)?.EndDate | date:'dd MMMM yyyy'}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>

    <div [style.width]="'20px'"></div>

    <mat-card class="submission-cards submission-card-days" fxLayoutAlign="center center">
        <div fxLayout="row" fxLayoutAlign="center" class="row">
            <div>
                <span class="extra-large-yellow mat-display-4">{{DaysToGo$ | async}}</span>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center" class="row">
            <div *ngIf="(DaysToGo$ | async) === 1"><span class="title-blue mat-h3">day to go</span></div>
            <div *ngIf="(DaysToGo$ | async) !== 1"><span class="title-blue mat-h3">days to go</span></div>
        </div>
    </mat-card>
</div>
