<div class="stepper">
    <mat-horizontal-stepper #stepper [selectedIndex]="selectedIndex" *ngIf="mediaObserver.isActive('gt-sm')"
        labelPosition="bottom" (selectionChange)="onSelectionChange($event)">
        <mat-step [completed]="false" *ngFor="let step of steps;" >
            <ng-template matStepLabel>{{step.title}} <span *ngIf="step.isIncomplete" class="incomplete" matTooltip="Please enter required information" ></span> <p class="gray mat-caption">{{step.subTitle}}</p>
            </ng-template>
            <ng-container *ngTemplateOutlet="step.template"></ng-container>
        </mat-step>
    </mat-horizontal-stepper>

    <!-- Mobile Only Mode -->

    <div fxLayout="row" fxLayoutAlign="center center"
        *ngIf="!mediaObserver.isActive('gt-sm') && ( this.activeStep && !this.activeStep.showMobileSteps ) ">

        <div fxLayout>
            <circle-progress [title]="ProgressStep" [percent]="Progress" [radius]="80" [space]="-16" [showUnits]="false"
                             [outerStrokeWidth]="16" [innerStrokeWidth]="16" [titleFontSize]="30" [titleFontWeight]="900"
                             [outerStrokeColor]="'#78C000'" [innerStrokeColor]="'rgb(207,204,204)'" [animation]="true"
                             [showSubtitle]="false" [showZeroOuterStroke]="false" [animationDuration]="300"></circle-progress>

        </div>
        <div fxLayout="column" fxLayoutAlign="center center">
            <div>
                <p class="title-blue mat-body-2">{{StepName}}</p>
            </div>
            <div>
                <div *ngIf="NextStepName" class="title-gray mat-body-1">Next: {{NextStepName}}</div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="!mediaObserver.isActive('gt-sm')">
        <mat-vertical-stepper [ngClass]="MobileModeClass" #stepper [selectedIndex]="selectedIndex"
            labelPosition="bottom" (selectionChange)="onSelectionChange($event)">
            <mat-step [completed]="false" *ngFor="let step of steps;">
                <ng-template matStepLabel>{{step.title}}<p class="gray mat-caption">{{step.subTitle}}</p>
                </ng-template>
            </mat-step>
        </mat-vertical-stepper>
        <ng-container *ngTemplateOutlet="activeStep?.template"></ng-container>
    </ng-container>

</div>
