import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SubmissionService} from 'src/app/submissions/services/submission.service';
import {Observable} from 'rxjs';
import {Consultation} from 'src/app/models/consultation';
import {UserDetails} from 'src/app/models/userDetails';

@Component({
    selector: 'tdc-submission-received',
    templateUrl: './submission-received.component.html',
    styleUrls: ['./submission-received.component.scss']
})
export class SubmissionReceivedComponent implements OnInit {

    submissionsId: number;
    roundId: number;

    constructor(private submissionService: SubmissionService, private route: ActivatedRoute) {
    }

    public ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.submissionsId = parseInt(params.submissionId);
            this.roundId = parseInt(params.roundId);

        });
    }

    public get User$(): Observable<UserDetails> {
        return this.submissionService.UserDetails$;
    }

    public get Consultation$(): Observable<Consultation> {
        return this.submissionService.Consultation$;
    }
}
