<div class="container-title">
    <div class="mat-title title">Public consultations</div>
    <div class="description mat-h1">Have your say on current proposals and discussions</div>
</div>

<div class="table-container">
    <mat-tab-group [selectedIndex]="default">
        <mat-tab label="Open consultations">
            <mat-form-field appearance="outline" class="search-field">
                <input matInput [formControl]="openSearchControl" placeholder="Search">
                <mat-hint *ngIf="!openSearchControl.value">
                    Type the name of the consultation here to filter the list below
                </mat-hint>
                <mat-hint *ngIf="openSearchControl.value && openSearchControl.value.length < 3">
                    Minimum of 3 characters required
                </mat-hint>
            </mat-form-field>

            <table mat-table #openSort="matSort" matSort [dataSource]="openConsultationDataSource"
                   class="mat-elevation-z8">

                <ng-container matColumnDef="Name">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef> Consultation</th>
                    <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
                </ng-container>

                <ng-container matColumnDef="EndDate">
                    <th mat-header-cell mat-sort-header arrowPosition="before" *matHeaderCellDef class="right-align">
                        Closing date
                    </th>
                    <td mat-cell *matCellDef="let element"
                        class="right-align"> {{element.EndDate | date:'dd MMMM yyyy'}} </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                    [routerLink]="['/my-council/public-consultation/submission/new', row.RoundID, formatForUri(row.Name)]"></tr>

            </table>
            <mat-hint *ngIf="HasNoOpenSearchResults" class="no-results">
                Your search - <b>{{openSearchControl.value}}</b> - did not match any open consultations.
            </mat-hint>
            <mat-paginator #openConsultationPaginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
            </mat-paginator>
        </mat-tab>

        <mat-tab label="Closed consultations">
            <mat-form-field appearance="outline" class="search-field">
                <input matInput [formControl]="closedSearchControl" placeholder="Search">
                <mat-hint *ngIf="!closedSearchControl.value">
                    Type the name of the consultation here to filter the list below
                </mat-hint>
                <mat-hint *ngIf="closedSearchControl.value && closedSearchControl.value.length < 3">
                    Minimum of 3 characters required
                </mat-hint>
            </mat-form-field>

            <table mat-table #closedSort="matSort" matSort [dataSource]="closedConsultationDataSource" matSortActive="EndDate" matSortDirection="desc" matSortDisableClear
                   class="mat-elevation-z8">

                <ng-container matColumnDef="Name">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef> Consultation</th>
                    <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
                </ng-container>

                <ng-container matColumnDef="EndDate">
                    <th mat-header-cell mat-sort-header arrowPosition="before" *matHeaderCellDef class="right-align">
                        Closed date
                    </th>
                    <td mat-cell *matCellDef="let element"
                        class="right-align">{{element.EndDate | date:'dd MMMM yyyy'}}</td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                    [routerLink]="['/my-council/public-consultation/search/consultation', row.RoundID, formatForUri(row.Name)]"></tr>
            </table>
            <mat-hint *ngIf="HasNoClosedSearchResults" class="no-results">
                Your search - <b>{{closedSearchControl.value}}</b> - did not match any open consultations.
            </mat-hint>
            <mat-paginator #closedConsultationPaginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
            </mat-paginator>
        </mat-tab>
    </mat-tab-group>

</div>
