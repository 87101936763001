<div fxLayoutGap="10px" class="info-box">
    <div fxLayoutAlign="center center">
        <i class="material-icons">info</i>
    </div>

    <div fxFlex="100%" fxLayoutAlign="left left">
        <div>
            <div class="info-title">{{titleText}}</div>
            <div class="info-link mat-subheading-1">
                <a (click)="click.emit()">{{linkText}}</a>
            </div>
        </div>
    </div>
</div>
