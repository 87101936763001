// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apimKey: "efd73a3b03814bcf8b7c624cc77774e6",
  apiKey : "c739c5ab-042a-44dc-bd25-abc5a7da41f6",
  baseUrl: "https://apim-tdc-dev-aue.azure-api.net/submissions",
  addyBaseUrl :"https://api.addy.co.nz",
  addyApiKey: "a30662136e9b44389a3aeccb687422e1"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
