import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Location} from '../../models/location';
import {environment} from '../../../environments/environment';
import {RoundSubject} from '../../models/roundSubject';
import {Observable} from 'rxjs';
import {File} from "../../models/file";
import {UserDetails} from "../../models/userDetails";
import {SubmissionSubject} from "../../models/submissionSubject";
import {Consultation} from '../../models/consultation';
import {map, tap} from 'rxjs/operators';
import * as moment from 'moment';
import {SubmissionSummary} from '../../models/submissionSummary';
import {SubmissionResponse} from '../../models/submissionResponse';

export const enum ConsultationStatus {
    Open = 'open',
    Closed = 'closed'
}


@Injectable({
    providedIn: 'root'
})
export class SubmissionDataService {
    apimKey: string;
    apiKey: string;
    baseUrl: string;
    addyBaseUrl: string;
    addyApiKey: string;
    headers: HttpHeaders;

    constructor(private http: HttpClient) {
        this.apimKey = environment.apimKey;
        this.apiKey = environment.apiKey;
        this.baseUrl = environment.baseUrl;
        this.addyBaseUrl = environment.addyBaseUrl;
        this.addyApiKey = environment.addyApiKey;

        this.headers = this.apimKey ? new HttpHeaders({ 'Ocp-Apim-Subscription-Key': this.apimKey }) : new HttpHeaders();
    }

    public getConsultations(status: ConsultationStatus): Observable<Consultation[]> {
        return this.http.get<Consultation[]>(`${this.baseUrl}/rounds/${status}/${this.apiKey}?format=json`, {
            headers: this.headers
        }).pipe(
            map(consultations =>
                consultations.map(consultation => {
                    consultation.EndDate = moment(consultation.EndDate);
                    return consultation;
                })
            )
        );
    }

    public getSubmissionSummaries(round: number): Observable<SubmissionSummary[]> {
        return this.http.get<SubmissionSummary[]>(`${this.baseUrl}/submissionsummaries/${round}/0/${this.apiKey}?format=json`, {
            headers: this.headers
        }).pipe(
            map(items => items.map(item => Object.assign(new SubmissionSummary(), item)))
        );
    }

    public getSubmissionFiles(round: number, subject: number): Observable<File[]> {
        return this.http.get<SubmissionSummary[]>(`${this.baseUrl}/submissionsummary/${round}/${subject}/${this.apiKey}?format=json`, {
            headers: this.headers
        }).pipe(
            map(items => items.reduce((acc, item) => acc.concat(item.Files), []))
        );
    }

    public getLocations(round: number): Observable<Location[]> {
        return this.http.get<Location[]>(`${this.baseUrl}/locations/${round}/${this.apiKey}?format=json`, {
            headers: this.headers
        });
    }

    public getRoundSubjects(round: number): Observable<RoundSubject[]> {
        return this.http.get<RoundSubject[]>(`${this.baseUrl}/roundsubjects/${round}/${this.apiKey}?format=json`, {
            headers: this.headers
        });
    }

    public postSubmission(consultation: Consultation, userDetails: UserDetails, subjects: SubmissionSubject[], files: File[]): Observable<SubmissionResponse> {
        // Create submission subjects API model
        const subjectsPost: any = subjects
          .filter(subject => subject.IsAnswered)
          .map(subject => {
            return {
                RoundSubjectId: subject.RoundSubject.RoundSubjectId,
                Subject: subject.RoundSubject.Subject,
                RoundSubjectAnswerId: subject.MultiChoiceAnswerId,
                Answer: subject.MultiChoiceAnswer && subject.MultiChoiceAnswer.Answer,
                Summary: subject.AnswerText
            };
        });

        // Create files API model
        const filesPost: any = files.map(file => {
            return {
                bytes: this.base64ArrayBuffer(file.Bytes),
                Name: file.Name,
                ContentType: "application/octet-stream"
            };
        });

        // Create submission API model
        const submissionPost = {
            apiKey: this.apiKey,
            RoundID: consultation.RoundID,
            Title: userDetails.Title,
            FirstName: userDetails.FirstName,
            LastName: userDetails.LastName,
            Designation: userDetails.Designation,
            Organisation: userDetails.Organisation,
            Address1: userDetails.Address1,
            Address2: userDetails.Address2,
            Town: userDetails.City,
            Postcode: userDetails.Postcode,
            Phone: userDetails.Phone,
            Mobile: userDetails.Mobile,
            Email: userDetails.Email,
            Speak: userDetails.Speak,
            LocationId: userDetails.Location && userDetails.Location.LocationId,
            SubmissionSubjects: subjectsPost,
            Files: filesPost
        };

        return this.http.post<any>(`${this.baseUrl}/submission/${this.apiKey}?format=json`, submissionPost, {
            headers: this.headers
        });
    }



    private base64ArrayBuffer(arrayBuffer) {
        var base64 = ''
        var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'

        var bytes = new Uint8Array(arrayBuffer)
        var byteLength = bytes.byteLength
        var byteRemainder = byteLength % 3
        var mainLength = byteLength - byteRemainder

        var a, b, c, d
        var chunk

        // Main loop deals with bytes in chunks of 3
        for (var i = 0; i < mainLength; i = i + 3) {
            // Combine the three bytes into a single integer
            chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2]

            // Use bitmasks to extract 6-bit segments from the triplet
            a = (chunk & 16515072) >> 18 // 16515072 = (2^6 - 1) << 18
            b = (chunk & 258048) >> 12 // 258048   = (2^6 - 1) << 12
            c = (chunk & 4032) >> 6 // 4032     = (2^6 - 1) << 6
            d = chunk & 63               // 63       = 2^6 - 1

            // Convert the raw binary segments to the appropriate ASCII encoding
            base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d]
        }

        // Deal with the remaining bytes and 
            a = (chunk & 252) >> 2 // 252 = (2^6 - 1) << 2

        if (byteRemainder == 1) {
            chunk = bytes[mainLength]

            // Set the 4 least significant bits to zero
            b = (chunk & 3) << 4 // 3   = 2^2 - 1

            base64 += encodings[a] + encodings[b] + '=='
        } else if (byteRemainder == 2) {
            chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1]

            a = (chunk & 64512) >> 10 // 64512 = (2^6 - 1) << 10
            b = (chunk & 1008) >> 4 // 1008  = (2^6 - 1) << 4

            // Set the 2 least significant bits to zero
            c = (chunk & 15) << 2 // 15    = 2^4 - 1

            base64 += encodings[a] + encodings[b] + encodings[c] + '='
        }

        return base64
    }




}
