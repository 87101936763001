<div class="container-title">
    <div class="mat-title title">{{(Consultation$ | async)?.Name}}</div>
    <div class="description mat-h1">
        Submissions will be closed on {{(Consultation$ | async)?.EndDate | date:'dd MMMM yyyy'}} at 11:59 PM
    </div>
</div>

<tdc-summary *ngIf="isSummaryEnabled" [consultation$]="Consultation$"></tdc-summary>

<ngx-spinner
        bdOpacity="0.5"
        bdColor='rgba(100,100,100, .8)'
        size="medium"
        type="ball-circus"
        color="white">
    <p>Submission in progress</p>
    <p>Please wait...</p>
</ngx-spinner>

<tdc-stepper #stepper>
    <tdc-step title="Overview" (activeChange)="isSummaryEnabled = $event" [showMobileSteps]="true">
        <tdc-overview [subjects$]="Subjects$"></tdc-overview>
        <button mat-flat-button class="button-right" color="primary" (click)="stepper.next()">Start submission</button>
    </tdc-step>
    <tdc-step #aboutYou title="About you">
        <tdc-about-you [locations$]="Locations$" [userDetails$]="SavedUserDetails$"
                       [showPresentOption]="(Consultation$ | async)?.IsConsultative"
                       (userDetailsChanged)="onUserChanged($event)"
                       (requiredUserDetailsValidated)=onUserDetailsValidated($event)></tdc-about-you>
        <button mat-flat-button class="button-right" color="primary" (click)="stepper.next()"
                [disabled]="!isUserDetailsValid">Next >
        </button>
        <button mat-flat-button class="button-left" (click)="stepper.previous()">< Back</button>
    </tdc-step>
    <tdc-step #feedback title="Feedback" subTitle="{{ SubjectCount$ | async }} Question(s)">
        <tdc-feedback [subjects$]="Subjects$" [answers$]="SavedAnswers$"
                      (answersChanged)="onAnswersChanged($event)"></tdc-feedback>
        <button mat-flat-button class="button-right" color="primary" (click)="stepper.next()"
                [disabled]="!(HasAnswers$ | async)">Next ></button>
        <button mat-flat-button class="button-left" (click)="stepper.previous()">< Back</button>
    </tdc-step>
    <tdc-step #fileUpload title="Upload files" subTitle="(optional)">
        <tdc-file-upload [files$]="Files$" (fileAdded)="onFileAdded($event)"
                         (fileRemoved)="onFileRemoved($event)"></tdc-file-upload>
        <button mat-flat-button class="button-right" color="primary" (click)="stepper.next()">Next ></button>
        <button mat-flat-button class="button-left" (click)="stepper.previous()">< Back</button>
    </tdc-step>
    <tdc-step title="Review & submit">
        <tdc-review-submit [userDetails$]="UserDetails$" [answers$]="Answers$" [files$]="Files$"
                           [showPresentOption]="(Consultation$ | async)?.IsConsultative"
                           (movedToAboutYou)="stepper.setActive(aboutYou)"
                           (movedToFeedback)="stepper.setActive(feedback)"
                           (movedToFileUpload)="stepper.setActive(fileUpload)"></tdc-review-submit>
        <button mat-flat-button type="submit" class="button-submit" (click)="submitSubmission()"
                [disabled]="!isUserDetailsValid || !(HasAnswers$ | async)">Submit
        </button>
        <button mat-flat-button class="button-left" (click)="stepper.previous()">< Back</button>
    </tdc-step>
</tdc-stepper>
