import { RoundSubject } from './roundSubject';
import {Answer} from "./answer";

export class SubmissionSubject {
    RoundSubject: RoundSubject = null;
    MultiChoiceAnswerId: number = null;
    AnswerText: string = null;

    public get IsAnswered(): boolean {
        return this.MultiChoiceAnswerId != null || (this.AnswerText && this.AnswerText.length > 0);
    }

    public get MultiChoiceAnswer(): Answer {
        return this.RoundSubject.Answers.find(answer =>
            answer.RoundSubjectAnswerId === this.MultiChoiceAnswerId);
    }
}
