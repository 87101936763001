import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Address} from '../../models/address';


@Injectable({
    providedIn: 'root'
})
export class AddressFindService {

    addyBaseUrl: string;
    addyApiKey: string;

    constructor(private http: HttpClient) {
        this.addyBaseUrl = environment.addyBaseUrl;
        this.addyApiKey = environment.addyApiKey;
    }

    public getAddressSuggestion(searchText: string): Observable<Address[]> {
        return this.http.get<any>(`${this.addyBaseUrl}/search?key=${this.addyApiKey}&max=10&s=${searchText}`).pipe(
            map(addresses =>
                addresses.addresses.map(add => {
                    const address = (add.a as string).split(',');

                    const address1 = address[0].trim();
                    let address2 = null;
                    let cityAndPostcode = null;

                    if (address.length == 2) {
                        cityAndPostcode = address[1];
                    } else {
                        address2 = address[1].trim();
                        cityAndPostcode = address[2];
                    }

                    let city = cityAndPostcode.slice(0, -4).trim();
                    let postcode = cityAndPostcode.slice(-4).trim();

                    return (new Address(address1, address2, city, postcode));
                })
            )
        );
    }
}
