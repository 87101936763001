import { Injectable } from '@angular/core';
import {Observable, ReplaySubject} from "rxjs";
import {Consultation} from "../../models/consultation";
import {
    distinctUntilChanged,
    map,
    mergeAll,
    mergeMap,
    shareReplay,
    switchMap,
    take,
    withLatestFrom
} from "rxjs/operators";
import {SubmissionSummary} from "../../models/submissionSummary";
import {File} from '../../models/file';
import {ConsultationStatus, SubmissionDataService} from "./submission-data.service";

@Injectable({
  providedIn: 'root'
})
export class PastSubmissionsService {
    private RoundId$: ReplaySubject<number> = new ReplaySubject(1);
    private SubmissionId$: ReplaySubject<number> = new ReplaySubject(1);

  public ClosedConsultations$: Observable<Consultation[]>;

  public Consultation$: Observable<Consultation>;
  public Summaries$: Observable<SubmissionSummary[]>;

  public SelectedSummaries$: Observable<SubmissionSummary[]>;

  constructor(private submissionDataService: SubmissionDataService) {
    this.ClosedConsultations$ = this.submissionDataService.getConsultations(ConsultationStatus.Closed).pipe(
        shareReplay(1)
    );

    // Whenever the round ID changes, find the correct consultation
    this.Consultation$ = this.RoundId$.pipe(
        distinctUntilChanged(),
        switchMap(() => this.ClosedConsultations$),
        withLatestFrom(this.RoundId$),
        map(([consultations, roundId]) =>
            consultations.find(consultation => consultation.RoundID === roundId))
    );

    // Whenever the consultation changes, find the correct summaries
    this.Summaries$ = this.Consultation$.pipe(
        switchMap(consultation => this.submissionDataService.getSubmissionSummaries(consultation.RoundID)),
        shareReplay(1)
    );

    this.SelectedSummaries$ = this.SubmissionId$.pipe(
        switchMap(() => this.Summaries$),
        withLatestFrom(this.SubmissionId$),
        map(([summaries, subjectId]) =>
            summaries.filter(summary => summary.SubmissionsId === subjectId))
    );
  }

  public selectRound(roundId: number): void {
    this.RoundId$.next(roundId);
  }

  public selectSubmission(submissionId: number): void {
      this.SubmissionId$.next(submissionId);
  }

  public search(searchText: string): Observable<SubmissionSummary[]> {
    searchText = searchText.toLowerCase();

    return this.Summaries$.pipe(
        map(summaries =>
            summaries.filter(summary =>
                !searchText ||
                summary.FullName.toLowerCase().includes(searchText) ||
                summary.RoundSubject.toLowerCase().includes(searchText) ||
                summary.Summary.toLowerCase().includes(searchText))),
        take(1)
    );
  }

  public downloadCurrentSubmissionFiles(): Observable<File> {
    // Get only files for the first submission subject, as the API appears to be
    // returning all files on every submission subject
    return this.SelectedSummaries$.pipe(
        take(1),
        withLatestFrom(this.RoundId$),
        mergeMap(([summaries, roundId]) =>
            this.submissionDataService.getSubmissionFiles(roundId, summaries[0].SubmissionSubjectId)),
        mergeAll()
    );
  }
}
