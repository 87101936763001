import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SubmissionNewComponent} from '../submissions/components/new-submission/submission-new.component';
import {ConsultationSearchComponent} from '../submissions/components/consultation-search/consultation-search.component';
import {ConsultationComponent} from '../submissions/components/consultation/consultation.component';
import {SubmissionComponent} from '../submissions/components/submission/submission.component';
import {SubmissionReceivedComponent} from '../submissions/components/new-submission/submission-received/submission-received.component';

const routes: Routes = [
    {
        path: 'my-council/public-consultation/submission/new/:roundId/:consultationName',
        component: SubmissionNewComponent,
    },
    {
        path: 'my-council/public-consultation/submission/received/:roundId/:submissionId',
        component: SubmissionReceivedComponent,
    },
    {
        path: 'my-council/public-consultation/search',
        pathMatch: 'full',
        redirectTo: 'my-council/public-consultation/search/'
    },
    {
        path: 'my-council/public-consultation',
        pathMatch: 'full',
        redirectTo: 'my-council/public-consultation/search/'
    },
    {
        path: 'my-council',
        pathMatch: 'full',
        redirectTo: 'my-council/public-consultation/search/'
    },

    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'my-council/public-consultation/search/'
    },

    {
        path: 'my-council/public-consultation/search/:activeTab',
        component: ConsultationSearchComponent,
    },
    {
        path: 'my-council/public-consultation/search/consultation/:roundId/:consultationName',
        component: ConsultationComponent,
    },
    {
        path: 'my-council/public-consultation/search/submission/:roundId/:submissionsId',
        component: SubmissionComponent,
    },
];


@NgModule({
    imports: [
        RouterModule.forRoot(routes, {})
    ],
    exports: [
        RouterModule
    ],
    declarations: []
})
export class AppRoutingModule {
}
