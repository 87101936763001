import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Observable} from 'rxjs';
import {UserDetails} from 'src/app/models/userDetails';
import {SubmissionSubject} from 'src/app/models/submissionSubject';
import {File} from 'src/app/models/file';
import {map} from "rxjs/operators";

@Component({
    selector: 'tdc-review-submit',
    templateUrl: './review-submit.component.html',
    styleUrls: ['./review-submit.component.scss']
})
export class ReviewSubmitComponent implements OnInit {

    @Input() userDetails$: Observable<UserDetails>;
    @Input() answers$: Observable<SubmissionSubject[]>;
    @Input() files$: Observable<File[]>;
    @Input() showPresentOption: boolean = true;

    @Output() movedToAboutYou: EventEmitter<number> = new EventEmitter();
    @Output() movedToFeedback: EventEmitter<number> = new EventEmitter();
    @Output() movedToFileUpload: EventEmitter<number> = new EventEmitter();

    public TotalAnswerCount$: Observable<number>;
    public AnsweredCount$: Observable<number>;
    public TotalFileCount$: Observable<number>;
    public TotalFileSize$: Observable<number>;

    constructor() {
    }

    public ngOnInit(): void {
        this.TotalAnswerCount$ = this.answers$.pipe(
            map(answers => answers.length)
        );

        this.AnsweredCount$ = this.answers$.pipe(
            map(answers => answers.filter(answer => answer.IsAnswered).length)
        );

        this.TotalFileCount$ = this.files$.pipe(
            map(files => files.length)
        );

        this.TotalFileSize$ = this.files$.pipe(
            map(files => files.reduce((sum, current) => sum + current.Size, 0))
        );
    }

    public goToAboutYou(): void {
        this.movedToAboutYou.emit();
    }

    public goToFeedback(): void {
        this.movedToFeedback.emit();
    }

    public goToFileUpload(): void {
        this.movedToFileUpload.emit();
    }
}
