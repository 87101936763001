export class SubmissionResponse {
    Text: string;
    Success: boolean;
    SubmissionId: number;   
    RoundId:number;
   

    constructor(text: string, success: boolean, submissionId: number) {
        this.Text = text;
        this.Success = success;
        this.SubmissionId = submissionId;          
        }
}

