import {Injectable} from '@angular/core';
import {ConsultationStatus, SubmissionDataService} from "./submission-data.service";
import {BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject} from "rxjs";
import {map, shareReplay, switchMap, take, withLatestFrom, find, filter, tap, startWith} from "rxjs/operators";
import {UserDetails} from "../../models/userDetails";
import {Location} from "../../models/location";
import {File} from "../../models/file";
import {RoundSubject} from "../../models/roundSubject";
import {SubmissionSubject} from "../../models/submissionSubject";
import {Consultation} from '../../models/consultation';
import {SubmissionResponse} from '../../models/submissionResponse';

@Injectable({
    providedIn: 'root'
})
export class SubmissionService {
    private RoundId$: ReplaySubject<number> = new ReplaySubject(1);

    // The following observables will only emit when a new consultation is loaded
    public Consultation$: Observable<Consultation>;
    public Locations$: Observable<Location[]>;
    public Subjects$: Observable<RoundSubject[]>;
    public SubjectCount$: Observable<number>;
    public OpenConsultations$: Observable<Consultation[]>;

    // Emits whenever user details change
    public UserDetails$: BehaviorSubject<UserDetails> = new BehaviorSubject(new UserDetails());

    // Emits whenever answers change
    public Answers$: ReplaySubject<SubmissionSubject[]> = new ReplaySubject(1);

    // Emits whenever new files are added or removed
    public Files$: BehaviorSubject<File[]> = new BehaviorSubject([]);

    constructor(private submissionDataService: SubmissionDataService) {
        // Whenever the round ID changes, find the correct consultation and reset details
        this.Consultation$ = this.RoundId$.pipe(
            switchMap(() => this.OpenConsultations$),
            withLatestFrom(this.RoundId$),
            map(([consultations, roundId]) =>
                consultations.find(consultation => consultation.RoundID === roundId))
        );

        this.OpenConsultations$ = this.submissionDataService.getConsultations(ConsultationStatus.Open).pipe(
            shareReplay(1)
        );

        this.Locations$ = this.Consultation$.pipe(
            switchMap(consultation => this.submissionDataService.getLocations(consultation.RoundID)),
            shareReplay(1)
        );

        this.Subjects$ = this.Consultation$.pipe(
            switchMap(consultation => this.submissionDataService.getRoundSubjects(consultation.RoundID)),
            map(subjects => subjects.sort((a, b) => a.Subject.localeCompare(b.Subject))),
            shareReplay(1)
        );

        this.SubjectCount$ = this.Subjects$.pipe(
            map(subjects => subjects.length)
        );

        // Create or restore answers whenever the subjects have changed
        this.Subjects$.pipe(
            withLatestFrom(this.Answers$.pipe(startWith([]))),
            map(([subjects, oldAnswers]: [RoundSubject[], SubmissionSubject[]]) => {
                return subjects.map(subject => {
                    const oldAnswer = oldAnswers.find(x => x.RoundSubject.RoundSubjectId === subject.RoundSubjectId);

                    const answer = new SubmissionSubject();
                    answer.RoundSubject = subject;

                    // Restore existing answer
                    if (!!oldAnswer) {
                        answer.MultiChoiceAnswerId = oldAnswer.MultiChoiceAnswerId;
                        answer.AnswerText = oldAnswer.AnswerText;
                    }

                    return answer;
                });
            })
        )
            .subscribe(answers => this.Answers$.next(answers));
    }

    public restore(roundId: number): void {
        this.RoundId$.next(roundId);
    }

    public updateUserDetails(userDetails: UserDetails): void {
        this.UserDetails$.next(userDetails);
    }

    public updateAnswers(answers: SubmissionSubject[]): void {
        this.Answers$.next(answers);
    }

    public addFile(file: File): void {
        this.Files$.pipe(
            take(1)
        )
            .subscribe(files => {
                files.push(file);
                this.Files$.next(files);
            });
    }

    public removeFile(index: number): void {
        this.Files$.pipe(
            take(1)
        )
            .subscribe(files => {
                files.splice(index, 1);
                this.Files$.next(files);
            });
    }

    public submit(): Observable<SubmissionResponse> {

        return combineLatest(this.Consultation$, this.UserDetails$, this.Answers$, this.Files$).pipe(
            switchMap(([consultation, userDetails, answers, files]) => {
                return this.submissionDataService.postSubmission(consultation, userDetails, answers, files)
            })
        ).pipe(
            map(response => new SubmissionResponse(response.Text, response.Success, response.SubmissionId)),
            take(1)
        );
    }
}
