import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatStepperModule} from '@angular/material/stepper';
import {MatIconModule} from '@angular/material/icon';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MatSortModule} from '@angular/material/sort';
import {MatDividerModule} from '@angular/material/divider';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ConsultationComponent} from './submissions/components/consultation/consultation.component';
import {SubmissionNewComponent} from './submissions/components/new-submission/submission-new.component';
import {OverviewComponent} from './submissions/components/new-submission/steps/overview/overview.component';
import {AppRoutingModule} from './app-routing/app-routing.module';
import {SubmissionComponent} from './submissions/components/submission/submission.component';
import {ConsultationSearchComponent} from './submissions/components/consultation-search/consultation-search.component';
import {HeaderComponent} from './shared/components/header/header.component';
import {FooterComponent} from './shared/components/footer/footer.component';
import {StepperComponent} from './shared/components/stepper/stepper.component';
import {AboutYouComponent} from './submissions/components/new-submission/steps/about-you/about-you.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FeedbackComponent} from './submissions/components/new-submission/steps/feedback/feedback.component';
import {FileUploadComponent} from './submissions/components/new-submission/steps/file-upload/file-upload.component';
import {StepComponent} from './shared/components/step/step.component';
import {SummaryComponent} from './submissions/components/new-submission/summary/summary.component';
import {SubmissionDataService} from "./submissions/services/submission-data.service";
import {SubmissionService} from "./submissions/services/submission.service";
import {DragDropDirective} from './submissions/components/new-submission/steps/file-upload/drag-drop.directive';
import {ReviewSubmitComponent} from './submissions/components/new-submission/steps/review-submit/review-submit.component';
import {FileSizePipe} from './shared/pipes/file-size.pipe';
import {AddressFindService} from './shared/services/address-find.service';
import {AddressFindComponent} from './shared/components/address-find/address-find.component';
import {SubmissionReceivedComponent } from './submissions/components/new-submission/submission-received/submission-received.component';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {NgxSpinnerModule}  from "ngx-spinner";
import { InfoBoxComponent } from './shared/components/info-box/info-box.component';


@NgModule({
    declarations: [
        AppComponent,
        ConsultationComponent,
        SubmissionNewComponent,
        OverviewComponent,
        SubmissionComponent,        
        ConsultationSearchComponent,
        HeaderComponent,
        FooterComponent,
        StepperComponent,
        AboutYouComponent,
        FeedbackComponent,
        FileUploadComponent,
        StepComponent,
        SummaryComponent,
        DragDropDirective,
        ReviewSubmitComponent,
        FileSizePipe,
        AddressFindComponent,
        SubmissionReceivedComponent,
        InfoBoxComponent
    ],
    imports: [
        MatGridListModule,
        MatCardModule,
        MatStepperModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        MatIconModule,
        MatExpansionModule,
        MatButtonModule,
        MatFormFieldModule,
        MatDividerModule,
        MatProgressSpinnerModule,
        MatInputModule,
        MatTableModule,
        MatSortModule,
        MatTabsModule,
        MatPaginatorModule,
        MatTooltipModule,
        FormsModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        FlexLayoutModule,
        MatRadioModule,
        MatSelectModule,
        HttpClientModule,
        MatAutocompleteModule,
        NgCircleProgressModule.forRoot({}),
        NgxSpinnerModule
       
    ],
    providers: [
        SubmissionService,
        SubmissionDataService,
        AddressFindService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
