import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';

@Component({
    selector: 'tdc-step',
    templateUrl: './step.component.html',
    styleUrls: ['./step.component.scss']
})
export class StepComponent implements OnInit {

    @ViewChild('template', {static: true}) template: TemplateRef<any>;

    @Input() title: string;
    @Input() subTitle: string;
    @Input() showMobileSteps: boolean = false;
    @Input() isIncomplete: boolean= false;

    @Output() activeChange: EventEmitter<boolean> = new EventEmitter<boolean>(true);

    constructor() {
    }

    public ngOnInit(): void {
    }

    public setActive(active: boolean): void {
        this.activeChange.emit(active);
    }
}
