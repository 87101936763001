import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Observable} from "rxjs";
import {Address} from "../../../models/address";
import {AddressFindService} from 'src/app/shared/services/address-find.service';
import {debounceTime, filter, switchMap} from "rxjs/operators";
import { MatLegacyOptionSelectionChange as MatOptionSelectionChange } from '@angular/material/legacy-core';

@Component({
    selector: 'tdc-address-find',
    templateUrl: './address-find.component.html',
    styleUrls: ['./address-find.component.scss']
})
export class AddressFindComponent implements OnInit {
    @Input() label: string;
    @Input() required: boolean = false;
    @Input() userFormGroup: UntypedFormGroup;
    @Input() userFormControlName: string;

    // Callback for populating the option value
    @Input() optionValue: (address: Address) => any;

    @Output() addressSelected: EventEmitter<Address> = new EventEmitter<Address>();

    public AddressSuggestion$: Observable<Address[]>;

    constructor(private addressFindService: AddressFindService) {
    }

    public ngOnInit(): void {
        this.AddressSuggestion$ = this.userFormGroup.controls[this.userFormControlName].valueChanges.pipe(
            debounceTime(200),
            filter(text => text.length > 2),
            switchMap(text => this.addressFindService.getAddressSuggestion(text))
        );
    }

    public onSelectionChange(event: MatOptionSelectionChange, address: Address): void {
        if (event.isUserInput) {
            this.addressSelected.emit(address);
        }
    }
}
