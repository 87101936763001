<div class="container-title">
    <div class="mat-title title">{{(Consultation$ | async)?.Name}}</div>
    <div class="description mat-h1">
        Submission will be closed on {{(Consultation$ | async)?.EndDate | date:'dd MMMM yyyy'}} at 11:59 PM
    </div>
</div>

<div fxLayout="column" fxLayoutAlign="center center">
    <i class="fa fa-check-circle fa-5x calendar-after submission-success-icon" aria-hidden="true"></i>
    <div class="mat-h1">Submission received</div>

    <p class="submission-success-para">Reference number: {{submissionsId}}</p>

    <p class="submission-success-para">Thank you for your feedback</p>

    <p class="submission-success-para">
        A confirmation email has been sent to {{ (User$ | async)?.Email }}
    </p>

    <p class="submission-success-para">
      We will be in touch once a decision is made. If you have any questions please contact info@tasman.govt.nz
    </p>

    <button mat-flat-button class="button-right" color="primary" [routerLink]="['/my-council/public-consultation/search/0']">
        View open consultations
    </button>
</div>
