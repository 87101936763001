<div class="container" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center end">
  <div>
    <a title="Go to home page" class="site-header-brand-link-default" href="https://www.tasman.govt.nz/">
      <img class="footer-tasman-logo" src="assets/images/tasman-logo-reverse.png" alt="Tasman District Council" title="Tasman District Council (v1.1Dp)">
    </a>
  </div>
  <div>
    <a class="footer-newzealand-government-logo" href="https://www.govt.nz/">
      <img src="assets/images/newzealand-government-footer.png" alt="New Zealand Government" title="New Zealand Government">
    </a>
  </div>
</div>
