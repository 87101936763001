<ng-container *ngIf="Consultation$ | async as consultation">
  <div class="container-title">
      <div class="mat-title title">{{consultation.Name}}</div>
      <div class="description mat-h1">
        Submission closed on {{consultation.EndDate | date:'dd MMMM yyyy'}} at {{consultation.EndDate | date:'hh:mm a'}}
      </div>
  </div>

    <div class="navigation mat-subheading-1">
        <a [routerLink]="['/my-council/public-consultation/search/consultation', consultation.RoundID, formatForUri(consultation.Name)]">
            Submission search
        </a>
    </div>

    <div class="closed-submission-container">
        <p class="title-blue mat-body-2">Submission details</p>
        <div>
            <div class="review">
                <p class="mat-subheading-1">Name</p>
                <p class="mat-body-2">{{FullName$ | async}}</p>
            </div>

        </div>

        <mat-divider></mat-divider>

        <p class="title-blue mat-body-2">Feedback</p>
        <div>
            <div *ngFor="let submission of (Summaries$ | async); let submissionIndex = index"
                [attr.data-index]="submissionIndex">
                <div fxLayout="row" fxLayoutAlign="left">
                    <span class="question mat-subheading-1">{{submission.RoundSubject}}</span>
                </div>

                <div class="review">
                    <div>
                        <p class="mat-body-1">{{submission.Summary}} </p>
                    </div>
                </div>
            </div>
        </div>

        <mat-divider></mat-divider>

        <ng-container *ngIf="!isDownloadComplete || files.length > 0">
            <p class="title-blue mat-body-2">Uploaded files</p>
            <mat-spinner *ngIf="!isDownloadComplete" [diameter]="40"></mat-spinner>

            <div *ngFor="let file of files">
                <button mat-flat-button (click)="saveFile(file)">{{file.Name}}<mat-icon color="primary" class="upside-down">publish</mat-icon></button>
            </div>
        </ng-container>
    </div>

    <div class="navigation mat-subheading-1">
        <a [routerLink]="['/my-council/public-consultation/search/consultation', consultation.RoundID, formatForUri(consultation.Name)]">
            Submission search
        </a>
    </div>
</ng-container>
