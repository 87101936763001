import {
    Component,
    OnInit,
    ContentChildren,
    QueryList,
    ViewChild,
    AfterViewInit, AfterViewChecked,
} from '@angular/core';
import {StepComponent} from "../step/step.component";
import {StepperSelectionEvent} from "@angular/cdk/stepper";
import {MediaObserver} from "@angular/flex-layout";
import {MatStepper} from "@angular/material/stepper";

@Component({
    selector: 'tdc-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit, AfterViewInit, AfterViewChecked {

    @ViewChild('stepper') public stepper: MatStepper;
    @ContentChildren(StepComponent) public steps: QueryList<StepComponent>;

    public selectedIndex: number = 0;
    public activeStep: StepComponent;

    constructor(public mediaObserver: MediaObserver) {
    }

    public ngOnInit(): void {
    }

    public ngAfterViewInit(): void {
        this.steps.first.setActive(true);
        setTimeout(() => this.activeStep = this.steps.first);
    }

    public ngAfterViewChecked(): void {
        // Workaround to override focus method to fix buggy stepper behaviour
        if (this.mediaObserver.isActive('gt-sm')) {
            let headers = this.stepper._stepHeader;
            headers.forEach(x => x.focus = () => {
                setTimeout(() => x._getHostElement().scrollIntoView({behavior: "smooth", block: "start"}));
            });
        }
    }

    public next(): void {
        this.stepper.next();
    }

    public previous(): void {
        this.stepper.previous();
    }

    public setActive(step: StepComponent): void {
        this.selectedIndex = this.steps.toArray().findIndex(s => s === step);
    }

    public onSelectionChange(event: StepperSelectionEvent): void {
        this.selectedIndex = event.selectedIndex;

        const steps = this.steps.toArray();

        steps[event.previouslySelectedIndex].setActive(false);
        steps[event.selectedIndex].setActive(true);
        this.activeStep = steps[event.selectedIndex];

        if (!this.mediaObserver.isActive('gt-sm')) {
            setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }));
        }
    }

    public get MobileModeClass(): string {
        return this.activeStep && this.activeStep.showMobileSteps ? 'mobile-visible' : 'mobile-hidden';
    }

    public get ProgressStep() {
        return (this.selectedIndex + 1).toString() + " of " + this.steps.length.toString();
    }

    public get Progress() {
        return (this.selectedIndex + 1) / this.steps.length * 100;
    }

    public get NextStepName(): string {
        let steps = this.steps.toArray()
        return steps[this.selectedIndex + 1] ? steps[this.selectedIndex + 1].title : null;
    }

    public get StepName(): string {
        return this.steps.toArray()[this.selectedIndex].title
    }
}
