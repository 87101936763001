import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {RoundSubject} from 'src/app/models/roundSubject';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {SubmissionSubject} from 'src/app/models/submissionSubject';
import {Observable} from "rxjs";
import {filter, map, switchMap, tap} from "rxjs/operators";

@Component({
    selector: 'tdc-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

    @Input() subjects$: Observable<RoundSubject[]>;
    @Input() answers$: Observable<SubmissionSubject[]>;

    @Output() answersChanged: EventEmitter<SubmissionSubject[]> = new EventEmitter<SubmissionSubject[]>(null);

    formGroup: UntypedFormGroup;

    FreeTextAnswerId: number = 1;
    MultiSelectAnswerId: number = 2;
    FreeTextAndMultiSelectAnswerId: number = 3;

    constructor(private formBuilder: UntypedFormBuilder) {
    }

    ngOnInit() {
        // If answers have changed, create a new form group based on these answers,
        // then listen for changes on the form and emit whenever they change.
        this.answers$.pipe(
            tap(answers => this.formGroup = this.formBuilder.group({
                answers: this.formBuilder.array(answers.map(answer => this.formBuilder.group(answer as any)))
            })),
            switchMap(() => this.formGroup.valueChanges)
        )
            .subscribe((formValues: any) => {
                const answers = formValues.answers.map(answer => Object.assign(new SubmissionSubject(), answer));
                this.answersChanged.emit(answers);
            });
    }
}
