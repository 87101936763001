<div>
    <p class="title-blue mat-h1">Overview</p>

    <mat-accordion>
        <mat-expansion-panel (expandedChange)="isPreviewExpanded = $event"
                             [ngClass]="{ 'preview-questions': isPreviewExpanded }">
            <mat-expansion-panel-header [collapsedHeight]="'100px'">
                <mat-panel-title class="sub-title-small-black-bold">
                    Preview feedback questions
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div *ngFor="let subject of (subjects$ | async); let questionIndex = index"
                 [attr.data-index]="questionIndex">
                <div fxLayout="row" fxLayoutAlign="left">
                    <span class="question-preview">{{subject.Subject}}</span>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <tdc-info-box (click)="goToMoreInfo()"
                  titleText="Privacy statement: All submissions, including submitters' names, will be made available to Councillors and the public on our website. Submissions will only be accepted if a name and contact details are supplied"
                  linkText="More information"></tdc-info-box>
</div>
