<div>
    <p class="title-blue mat-h1">Upload Files (optional)</p>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center top" fxLayoutAlign.lt-md="center center">
        <div class="drop-area" fxLayoutAlign="center center" appDragDrop (onFileDropped)="uploadFile($event)"
             [disabled]="UploadDisabled$ | async">
            <div fxLayout="column">
                <div class="drag-drop-text">Drag and drop files here or</div>
                <input #fileInput type="file" class="file" [accept]="SupportedFiles" multiple
                       (change)="uploadFile($event.target.files)" [disabled]="UploadDisabled$ | async">
                <button mat-flat-button class="file-upload mat-subheading-1" (click)="fileInput.click()">
                    Choose files
                </button>
            </div>
            <div fxLayout="row"></div>
        </div>

        <div fxLayout="column" fxLayoutGap="10px" class="uploaded-files">

            <div fxLayout="row" *ngFor="let fileData of (files$ | async); let idx = index" [attr.data-index]="idx">
                <div fxFlex="70%" fxLayout="column" class="file-upload-filename"> {{fileData.Name}}
                    ({{fileData.Size | fileSize}})
                </div>
                <div fxFlex="30%" fxLayout="column" fxLayoutAlign="center center">
                    <i class="fa fa-times-circle fa-2x" (click)="removeFile(idx)"></i>
                </div>
            </div>

            <div>
                <div fxLayout="row" class="fileUpload-fileName" class="file-upload-total-size">
                    Total file size {{(TotalFileSize$ | async) | fileSize}}
                </div>
                <span fxLayout="row" class="mat-caption" [ngClass]="{'red': isMaxFileSize}">
                    Max total file size is 10MB
                </span>
                <span fxLayout="row" class="mat-caption" [ngClass]="{'red': isInvalidFileType}">
                    Supported file types: {{SupportedFiles}}
                </span>
            </div>
        </div>
    </div>
</div>
