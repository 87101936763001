import {Component, OnInit} from "@angular/core";
import {SubmissionService} from "../../services/submission.service";
import {Location} from "../../../models/location";
import {File} from "../../../models/file";
import {Observable} from "rxjs";
import {UserDetails} from "../../../models/userDetails";
import {SubmissionSubject} from "../../../models/submissionSubject";
import {RoundSubject} from "../../../models/roundSubject";
import {filter, map, switchMap} from "rxjs/operators";
import {ActivatedRoute} from '@angular/router';
import {Consultation} from "../../../models/consultation";
import {Router} from '@angular/router';
import {NgxSpinnerService} from "ngx-spinner";

@Component({
    selector: 'tdc-submission-new',
    templateUrl: './submission-new.component.html',
    styleUrls: ['./submission-new.component.scss']
})
export class SubmissionNewComponent implements OnInit {

    public isSummaryEnabled: boolean = false;

    public SavedUserDetails$: Observable<UserDetails>;
    public SavedAnswers$: Observable<SubmissionSubject[]>;
    public consultation: Consultation;
    public isUserDetailsValid: boolean;

    roundId: number;

    constructor(private submissionService: SubmissionService,
                private route: ActivatedRoute,
                private router: Router,
                private spinner: NgxSpinnerService) {
    }

    public ngOnInit(): void {
        // Emit stored user data whenever submission changes
        this.SavedUserDetails$ = this.submissionService.Consultation$.pipe(
            switchMap(() => this.UserDetails$)
        );

        // Emit stored answers whenever submission changes
        this.SavedAnswers$ = this.submissionService.Consultation$.pipe(
            switchMap(() => this.Answers$)
        );

        this.Consultation$.subscribe(c => this.consultation = c);

        this.route.params.subscribe(params => {
            this.roundId = parseInt(params.roundId);
            this.submissionService.restore(this.roundId);
        });
    }

    public onUserChanged(userDetails: UserDetails): void {
        this.submissionService.updateUserDetails(userDetails);
    }

    public onAnswersChanged(answers: SubmissionSubject[]): void {
        this.submissionService.updateAnswers(answers);
    }

    public onFileAdded(file: File): void {
        this.submissionService.addFile(file);
    }

    public onFileRemoved(index: number): void {
        this.submissionService.removeFile(index);
    }

    public get Consultation$(): Observable<Consultation> {
        return this.submissionService.Consultation$;
    }

    public get Subjects$(): Observable<RoundSubject[]> {
        return this.submissionService.Subjects$;
    }

    public get SubjectCount$(): Observable<number> {
        return this.submissionService.SubjectCount$;
    }

    public get Locations$(): Observable<Location[]> {
        return this.submissionService.Locations$;
    }

    public get UserDetails$(): Observable<UserDetails> {
        return this.submissionService.UserDetails$;
    }

    public get Answers$(): Observable<SubmissionSubject[]> {
        return this.submissionService.Answers$;
    }

    public get Files$(): Observable<File[]> {
        return this.submissionService.Files$;
    }

    public submitSubmission(): void {
        this.spinner.show();
        this.submissionService.submit().subscribe(submissionResponse => {
            if (submissionResponse && submissionResponse.Success) {
                this.router.navigate(['/my-council/public-consultation/submission/received',
                    this.roundId, submissionResponse.SubmissionId]);
                this.spinner.hide();
            }
        })
    }

    public onUserDetailsValidated(isValid: boolean): void {
        this.isUserDetailsValid = isValid;
    }

    public get HasAnswers$(): Observable<boolean> {
        return this.Answers$.pipe(
          map(answers => answers.some(answer => answer.IsAnswered))
        );
    }
}
