import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Location} from 'src/app/models/location';
import {UserDetails} from "../../../../../models/userDetails";
import {Observable} from "rxjs";
import {switchMap, tap} from "rxjs/operators";
import {Address} from "../../../../../models/address";


@Component({
    selector: 'tdc-about-you',
    templateUrl: './about-you.component.html',
    styleUrls: ['./about-you.component.scss']
})
export class AboutYouComponent implements OnInit {

    @Input() userDetails$: Observable<UserDetails>;
    @Input() locations$: Observable<Location[]>;
    @Input() showPresentOption: boolean = true;

    @Output() userDetailsChanged: EventEmitter<UserDetails> = new EventEmitter<UserDetails>();
    @Output() requiredUserDetailsValidated: EventEmitter<boolean> = new EventEmitter<boolean>();

    formGroup: UntypedFormGroup;

    titles = ['', 'Mr', 'Mrs', 'Ms', 'Dr', "Rev"];

    constructor(private formBuilder: UntypedFormBuilder) {
    }

    public ngOnInit(): void {
        this.formGroup = this.formBuilder.group({userDetails: this.formBuilder.group(new UserDetails())});

        // If user details have changed, create a new form group based on these details,
        // then listen for changes on the form and emit whenever they change.
        this.userDetails$.pipe(
            tap(userDetails => {
                this.formGroup.controls.userDetails.patchValue(userDetails, {emitEvent: false});
                setTimeout(() => this.requiredUserDetailsValidated.emit(this.formGroup.valid));
            }),
            switchMap(() => this.formGroup.valueChanges)
        )
            .subscribe((formValues: any) => {
                const userDetails = Object.assign(new UserDetails(), formValues.userDetails);
                this.userDetailsChanged.emit(userDetails);
                this.requiredUserDetailsValidated.emit(this.formGroup.valid);
            });

    }

    public compareWith(location1: Location, location2: Location): boolean {
        return location1 && location2 && location1.LocationId === location2.LocationId;
    }

    public addressOptionValue(address: Address): string {
        return address.Address1;
    }

    public onAddressSelected(address: Address): void {
        this.formGroup.controls.userDetails.patchValue(address);
    }
}
