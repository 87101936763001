import {Component, OnInit, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {RoundSubject} from 'src/app/models/roundSubject';

@Component({
    selector: 'tdc-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

    @Input() subjects$: Observable<RoundSubject[]>;

    public isPreviewExpanded: boolean = false;

    constructor() {
    }

    public ngOnInit(): void {
    }

    public goToMoreInfo(): void {
        window.location.href = 'https://www.tasman.govt.nz/my-council/about-us/terms-and-conditions/website-terms-of-use/';
    }
}
