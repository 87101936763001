import {Component, OnInit, Input} from '@angular/core';
import {Consultation} from 'src/app/models/consultation';
import * as moment from 'moment';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Component({
    selector: 'tdc-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {
    @Input() consultation$: Observable<Consultation>;

    public DaysToGo$: Observable<number>;

    constructor() {
    }

    public ngOnInit(): void {
        this.DaysToGo$ = this.consultation$.pipe(
            map(consultation => moment(consultation.EndDate).diff(moment(), 'days'))
        );
    }
}
