<div>
    <p class="title-blue mat-h1"> About You</p>

    <form *ngIf="!!formGroup" [formGroup]="formGroup" novalidate>

        <div fxLayout="column" fxLayoutAlign="start start" formGroupName="userDetails" validate>

            <mat-form-field appearance="outline" class="form-field-short">
                <mat-label>Title</mat-label>
                <mat-select formControlName="Title">
                    <mat-option *ngFor="let title of titles" [value]="title">{{title}}</mat-option>
                </mat-select>
                <mat-hint></mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-field-medium">
                <mat-label>First name(s)</mat-label>
                <input matInput required formControlName="FirstName">
                <mat-error>
                    First name is required
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-field-medium">
                <mat-label>Last name</mat-label>
                <input matInput required formControlName="LastName">
                <mat-error>
                    Last name is required
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-field-large">
                <mat-label>Organisation you are submitting on behalf of</mat-label>
                <input matInput formControlName="Organisation">
                <mat-hint></mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-field-large">
                <mat-label>Position</mat-label>
                <input matInput formControlName="Designation">
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-field-large">
                <mat-label>Email</mat-label>
                <input matInput required formControlName="Email" email>
                <mat-error>
                    Valid email is required
                </mat-error>
            </mat-form-field>
            <tdc-address-find
                    label="Address"
                    [userFormGroup]="formGroup.controls['userDetails']"
                    userFormControlName="Address1"
                    [optionValue]="addressOptionValue"
                    (addressSelected)="onAddressSelected($event)"></tdc-address-find>
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.gt-xs="1em" class="form-field-large address-secondary">
                <mat-form-field fxFlex="auto" appearance="outline" class="form-field-short">
                    <mat-label>Suburb</mat-label>
                    <input matInput formControlName="Address2">
                </mat-form-field>
                <mat-form-field fxFlex="auto" appearance="outline" class="form-field-short">
                    <mat-label>Town / City</mat-label>
                    <input matInput required formControlName="City">
                    <mat-error>
                        Town / City is required
                    </mat-error>
                </mat-form-field>
                <mat-form-field fxFlex="auto" appearance="outline" class="form-field-short">
                    <mat-label>Postcode</mat-label>
                    <input matInput formControlName="Postcode" pattern="\d+" maxlength="6">
                </mat-form-field>
            </div>
            <mat-form-field appearance="outline" class="form-field-medium">
                <mat-label>Phone number</mat-label>
                <input matInput required formControlName="Phone" pattern="^[0-9+\(\)\s]*$"
                    maxlength="13">
                <mat-error>
                    Valid phone number is required
                </mat-error>
            </mat-form-field>

            <div *ngIf="showPresentOption" class="form-check-box-block">
                <div class="form-check-box">
                    <mat-checkbox #feedback formControlName="Speak">
                        Do you wish to verbally present in support of your feedback?
                    </mat-checkbox>
                </div>

                <mat-form-field *ngIf="feedback.checked" appearance="outline" class="form-field-normal">
                    <mat-label>At location</mat-label>
                    <mat-select formControlName="Location" [compareWith]="compareWith">
                        <mat-option *ngFor="let location of (locations$ | async)" [value]="location">
                            {{location.LocationName}}</mat-option>
                    </mat-select>
                    <mat-hint></mat-hint>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
