<div>
    <div class="heading">
        <p class="title-blue mat-h1">Feedback</p>
        <p class="info-title">
            Please answer as many questions as you like. At least one answer is needed.
        </p>
    </div>

    <form *ngIf="!!formGroup" [formGroup]="formGroup" novalidate>

        <div formArrayName="answers">

            <div *ngFor="let subject of (subjects$ | async); let questionIndex = index" [attr.data-index]="questionIndex">

                <div [formGroupName]="questionIndex.toString()">

                    <div>
                        <span class="mat-subheading-1">{{subject.Subject}}</span>
                    </div>

                    <div *ngIf="subject.AnswerTypeId == FreeTextAndMultiSelectAnswerId || subject.AnswerTypeId == MultiSelectAnswerId">

                        <div fxLayout="row" fxLayoutAlign="left">
                            <mat-radio-group aria-label="Select an option" formControlName="MultiChoiceAnswerId">
                                <mat-radio-button [value]="answer.RoundSubjectAnswerId"
                                    *ngFor="let answer of subject.Answers; let answerIndex = index"
                                    [attr.data-index]="answerIndex">
                                    {{answer.Answer}}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>

                    <div *ngIf="subject.AnswerTypeId == FreeTextAndMultiSelectAnswerId || subject.AnswerTypeId == FreeTextAnswerId">

                        <div fxLayout="row" fxLayoutAlign="left">

                            <div class=" answer-free-text">
                                <textarea #answerText class="text-answer" matInput maxlength="2500"
                                    formControlName="AnswerText"></textarea>
                            </div>
                        </div>
                        <div class="answer-free-text" fxLayoutAlign="left">
                            <span class="mat-subheading-2 answer-free-text-description">
                                If your answer is more than 2500 characters you may choose to upload a file instead in Step 4
                            </span>
                            <span class="character-counter">{{answerText.textLength}} / 2500</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
