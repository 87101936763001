export class Address {
    Address1: string;
    Address2: string;
    City: string;
    Postcode: string;

    constructor(address1: string, address2: string, city: string, postcode: string) {
        this.Address1 = address1;
        this.Address2 = address2;
        this.City = city;
        this.Postcode = postcode;
    }
}
