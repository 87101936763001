import {File} from 'src/app/models/file';


export class SubmissionSummary {
    SubmissionsId: number;
    SubmissionSubjectId: number;
    FullName: string;
    RoundSubject:string;
    Summary:string;
    Files: File[];
}
