<mat-form-field [formGroup]="userFormGroup" appearance="outline" class="form-field-large">
    <mat-label>{{label}}</mat-label>
    <input type="text" matInput [required]="required" [matAutocomplete]="auto" [formControlName]="userFormControlName" placeholder="Address search">
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let address of (AddressSuggestion$ | async)" [value]="optionValue(address)" (onSelectionChange)="onSelectionChange($event, address)">
            <p>{{address.Address1}}, <span *ngIf="address.Address2">{{address.Address2}}, </span>{{address.City}} {{address.Postcode}}</p>
        </mat-option>
    </mat-autocomplete>
    <mat-error>
        {{label}} is required
    </mat-error>
</mat-form-field>
