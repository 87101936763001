import {Location} from './location'

export class UserDetails {
    Title: string = "";
    FirstName: string = "";
    LastName: string = "";
    Designation: string = "";
    Organisation: String = "";
    Address1: string = "";
    Address2: string = "";
    City: string = "";
    Postcode: string = "";
    Phone: string = "";
    Mobile: string = "";
    Email: String = "";
    Speak: boolean = false;

    Location: Location = null;
}
